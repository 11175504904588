@import '~antd/dist/antd.css';



body, html {
    padding: 0;
    margin: 0;
}

body {
    background-color: #151515;
}

.ant-input, #root .ant-select-selector {
    background-color: #2B2B2B;
    color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #2B2B2B;
    color: white;
}

.ant-checkbox-wrapper:hover .ant-checkbox:after, .ant-checkbox:hover:after {
    border: 1px solid #e8561f!important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #e8561f;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e8561f;
}